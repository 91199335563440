<template>
  <div class="flex flex-row">
    <div class="content dotted-background">
      <div
        ref="applicantHeaderWrapper"
        class="sticky top-0 z-10 border-b border-proxify-gray-200"
      >
        <ApplicantHeader
          :applicant="applicant"
          :rating="applicant.average_rating"
          :on-un-reject="unRejectApplicant"
          :is-hired="Boolean(applicant.is_hired)"
          :is-rejected="Boolean(applicant.is_rejected)"
          :is-fast-tracked="!!applicant.fast_tracked"
          :is-stage-changing="isStageChanging"
          :on-hover-badge="handleHoverBadge"
          :handle-change-stage="handleChangeStage"
          :adjacent-applications="adjacentApplications"
          :grade="applicant.grade"
          :english-score="applicant.english_cefr_score"
          :codility-score="codilityScore"
          :is-loading="isLoading"
          :supply-demand-ratio="applicant.supply_demand_ratio"
          @reject="rejectApplicant"
        />
      </div>
      <div
        class="flex py-6 px-8 bg-white border-t border-proxify-gray-200"
        :class="
          isNotificationBarActive || isTodosMenuActive
            ? 'flex'
            : 'max-md:hidden md:flex lg:hidden'
        "
      >
        <ApplicantScore
          :is-loading="isLoading"
          :english-score="applicant.english_cefr_score"
          :codility-score="codilityScore"
          :rating="applicant.average_rating"
          :grade="applicant.grade"
          :hiring-probability="applicant.hiring_probability"
          :profile-probability="applicant.profile_probability"
          :applied-at="applicant.applied_at"
          class="flex-1"
        />
        <div class="border-l border-proxify-gray-200 mx-6"></div>
        <FinalGrade
          :grade="applicant.grade"
          :is-loading="isLoading"
        />
      </div>

      <!-- Applicant Body -->
      <div
        class="grid divide-x divide-proxify-gray-200 bg-white"
        :class="
          isNotificationBarActive || isTodosMenuActive
            ? 'grid-cols-3'
            : 'md:grid-cols-3 lg:grid-cols-2'
        "
      >
        <div class="applicant-information g:col-span-1">
          <div
            class="px-8"
            :class="
              isNotificationBarActive || isTodosMenuActive
                ? 'hidden'
                : 'flex md:max-lg:hidden'
            "
          >
            <ApplicantScore
              :is-loading="isLoading"
              :english-score="applicant.english_cefr_score"
              :codility-score="codilityScore"
              :rating="applicant.average_rating"
              :grade="applicant.grade"
              :hiring-probability="applicant.hiring_probability"
              :profile-probability="applicant.profile_probability"
              :applied-at="applicant.applied_at"
              class="flex-1"
            />
            <div class="border-l border-proxify-gray-200 mx-6"></div>
            <FinalGrade
              :grade="applicant.grade"
              :is-loading="isLoading"
            />
          </div>
          <ApplicantInformation
            v-show="!isLoading"
            :is-loading="isLoading || isMenuOptionsLoading"
            :data="applicant"
            :update-stage="updateStage"
            :menu-options="menuOptions"
            :handle-change-stage="handleChangeStage"
            :open-application-form-dialog="openApplicationFormDialog"
          />
        </div>
        <div
          class="applicant-activity"
          :class="
            isNotificationBarActive || isTodosMenuActive
              ? 'col-span-2'
              : 'md:col-span-2 lg:col-span-1'
          "
        >
          <ActivitySwitcher :current-tab="currentTab" />
          <ActivityMessages
            v-if="currentTab === 'emails'"
            class="activities-container"
            :messages="emails"
            :subjects="emailSubjects"
            :get-email-subjects="getEmailSubjects"
            :on-select-subject="handleSelectSubject"
            :selected-subject="selectedSubject"
            :on-receive-message="handleReceiveMessage"
            :on-delete-message="handleDeleteMessage"
            :applicant="applicant"
            :is-loading="isEmailLoading || isMenuOptionsLoading"
          />
          <ActivityInterviewList
            v-else-if="currentTab === 'interviews'"
            v-model:data="applicant"
            class="activities-container"
            :interviews="interviews"
            :interviewers="menuOptions?.interviewers"
            :on-reschedule="getApplicantInterviews"
            :recruiters="recruiters"
            :on-reject="rejectApplicant"
            :is-loading="interviewListIsLoading"
            :get-applicant-interviews="getApplicantInterviews"
            :codility-score="codilityScore"
            :skills="skills"
          />
          <ActivityNotes
            v-else-if="currentTab === 'notes'"
            class="activities-container"
            :notes="notes"
            :on-receive-note="handleReceiveNote"
            :on-delete-note="handleDeleteNote"
            :on-edit-note="handleEditNote"
            :applicant="applicant"
            :is-loading="isMenuOptionsLoading"
          />
          <ActivityTests
            v-else-if="currentTab === 'tests'"
            class="activities-container"
            :tests="codilityTestLinks"
            :fetch-tests="getApplicantTests"
            :desired-test-date="applicant.desired_test_date"
            :is-loading="isApplicantTestsLoading"
          />
          <ActivityHistory
            v-else-if="currentTab === 'history'"
            class="activities-container"
            :stage="applicant.stage"
          />
          <ApplicantTodos
            v-else-if="currentTab === 'todos'"
            class="activities-container"
          />
          <ActivityComingSoon
            v-else
            class="activities-container"
          />
        </div>
      </div>
    </div>

    <HintBox
      v-if="showFastTrackedHint"
      :x="240"
      :y="245"
      title="Fast-tracked candidate"
      text="This candidate is marked as fast-tracked, based on skills and background"
    />

    <PDialog
      v-model="isConfirmationDialogOpen"
      position="standard"
      persistent
    >
      <ConfirmationPopup
        :on-cancel="closeConfirmationPopupDialog"
        :is-loading="isStageChanging"
        :is-interviewer-required="assigneesRequiredForStageChange?.interviewer"
        :initial-recruiter="applicant.assignees?.recruiter?.id"
        :initial-interviewer="applicant?.interviewers?.[newStage.id]?.id"
        :is-recruiter-required="assigneesRequiredForStageChange?.recruiter"
        :application-id="applicant.id"
        :applicant-name="applicant.full_name"
        :on-update-recruiter="handleUpdateAssignees"
        :current-stage="applicant.stage"
        :country="applicant.country"
        :monthly-rate="
          applicant.rate?.monthly_rate_updated ?? applicant.rate?.monthly_rate
        "
        :gig-overtime-hourly-rate="
          applicant.rate?.gig_overtime_hourly_rate_updated ??
          applicant.rate?.gig_overtime_hourly_rate
        "
        :new-stage="newStage"
        :is-rate-guidance-enabled="applicant?.compensation_plan === 'monthly'"
        @change-stage="onConfirmStageChange"
      />
    </PDialog>

    <PDialog
      v-model="isStageActionDialogOpen"
      position="standard"
      persistent
    >
      <StageActionPopup
        :on-cancel="onStageActionCancel"
        :on-confirm="onStageActionPerform"
        :is-loading="isStageActionLoading"
        :actions="stageEmailActions"
        :application-id="$route.params.id"
      />
    </PDialog>

    <PDialog
      v-model="isReminderDialogOpen"
      position="standard"
      persistent
    >
      <ReminderPopup
        :on-cancel="onReminderCancel"
        :on-confirm="onReminderConfirm"
        :on-close="onReminderClose"
      />
    </PDialog>

    <ApplicationForm
      v-if="applicant.id"
      ref="formDialog"
      :initial-data="applicant"
      :on-submit="handleSubmitApplicationForm"
      :upload-progress="uploadProgress"
      title="Edit Details"
      :next-stage-name="
        isSourcedToNonSourcingStages ? newStage.name : undefined
      "
      :previous-stage-name="
        isSourcedToNonSourcingStages ? applicant.stage.name : undefined
      "
      @cancel="newStage = {}"
    ></ApplicationForm>
  </div>
</template>

<script>
import ActivityHistory from '@/components/Elements/Applicant/ApplicantActivityHistory.vue';
import ActivityInterviewList from '@/components/Elements/Applicant/ApplicantActivityInterviewList.vue';
import ActivityComingSoon from '@/components/Elements/Applicant/ApplicantActivityComingSoon.vue';
import ActivityMessages from '@/components/Elements/Applicant/ApplicantActivityMessages.vue';
import ActivityNotes from '@/components/Elements/Applicant/ApplicantActivityNotes.vue';
import ActivityTests from '@/components/Elements/Applicant/ApplicantActivityTests.vue';
import ApplicantTodos from '@/components/Elements/Applicant/ApplicantActivityTodos.vue';
import ActivitySwitcher from '@/components/Elements/Applicant/ApplicantActivitySwitcher.vue';
import ApplicantHeader from '@/components/Elements/Applicant/ApplicantHeader.vue';
import ApplicantInformation from '@/components/Elements/Applicant/ApplicantInformation.vue';
import HintBox from '@/components/Elements/Scaffold/HintBox.vue';
import ConfirmationPopup from '@/components/Elements/Application/ApplicationStageChangeConfirmationPopup.vue';
import ReminderPopup from '@/components/Elements/Application/ReminderPopup.vue';
import StageActionPopup from '@/components/Elements/Application/StageActionPopup.vue';
import { PDialog } from '@/components/ProxifyUI';

import { computed, watch, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import api from '@/api';
import snackbarMessagesMixin from '@/mixins/snackbarMessagesMixin';
import titleMixin from '@/mixins/titleMixin';
import ApplicationForm from '@/components/Elements/Application/ApplicationForm.vue';
import ApplicantScore from '@/components/Elements/Applicant/ApplicantScore.vue';
import FinalGrade from '@/components/Elements/Applicant/ApplicantFinalGradePopup.vue';

import useApplicantInfo from '@/composables/useApplicantInfo';
import { useBroadcastChannel, useElementBounding } from '@vueuse/core';
import useNormalizedWindowSize from '@/composables/useNormalizedWindowSize';
import { useRouteParams } from '@vueuse/router';
import { interviewUtils } from '@/utils';

export default {
  name: 'ApplicantProfile',
  title: '',
  components: {
    ApplicantTodos,
    ApplicationForm,
    ActivityHistory,
    ActivityComingSoon,
    ActivityInterviewList,
    ActivityMessages,
    ActivityNotes,
    ActivityTests,
    ActivitySwitcher,
    ApplicantHeader,
    ApplicantInformation,
    ConfirmationPopup,
    HintBox,
    ReminderPopup,
    StageActionPopup,
    PDialog,
    ApplicantScore,
    FinalGrade,
  },
  mixins: [snackbarMessagesMixin, titleMixin],
  setup() {
    const store = useStore();
    const applicantHeaderWrapper = ref(null);
    const { bottom } = useElementBounding(applicantHeaderWrapper);
    const { height } = useNormalizedWindowSize();
    const containerHeight = computed(() => {
      return `${height.value - bottom.value - 106}px`;
    });
    const interviews = ref([]);
    const interviewListIsLoading = ref(true);
    const { applicant: applicantInfo, getApplicantInfo } = useApplicantInfo();
    const { data: channelMessage } = useBroadcastChannel({
      name: 'data-needs-update',
    });

    const applicationId = useRouteParams('id');
    const getApplicantInterviews = async () => {
      const id = applicationId.value;
      const response = await api.applications.interviews.get({ id });
      interviews.value = response.data.data.reverse();
      interviewListIsLoading.value = false;
    };

    const isNotificationBarActive = computed(
      () => store.state.settings.isNotificationBarActive
    );
    const isTodosMenuActive = computed(
      () => store.state.todos.isTodosMenuActive
    );

    watch(
      () => channelMessage.value,
      (newValue) => {
        if (newValue.data === 'application') {
          getApplicantInfo();
        } else if (newValue.data === 'interviews') {
          getApplicantInterviews();
        }
      }
    );

    return {
      applicantInfo,
      getApplicantInterviews,
      interviews,
      interviewListIsLoading,
      containerHeight,
      applicantHeaderWrapper,
      isNotificationBarActive,
      isTodosMenuActive,
    };
  },
  data() {
    return {
      applicant: {},
      assigneesRequiredForStageChange: {},
      isApplicantTestsLoading: true,
      emails: [],
      codilityTestLinks: [],
      emailSubjects: null,
      selectedSubject: {},
      isEmailLoading: true,
      isLoading: false,
      isMenuOptionsLoading: false,
      isStageChanging: false,
      codilityTestsAreLoading: false,
      codilityTests: [],
      menuOptions: {
        recruiter: [],
        sourcer: [],
        stage: [],
        disabledStages: [],
      },
      adjacentApplications: null,
      newStage: {},
      notes: [],
      showFastTrackedHint: false,
      isStageActionLoading: false,
      stageEmailActions: [],
      stageChangeEventContext: {},
      uploadProgress: 100,
      isConfirmationDialogOpen: false,
      isStageActionDialogOpen: false,
      isReminderDialogOpen: false,
      timer: null,
    };
  },
  computed: {
    ...mapState({
      dataNeedsUpdate: (state) => state.applicant.dataNeedsUpdate,
      interviewsNeedUpdate: (state) => state.applicant.interviewsNeedUpdate,
      recruiterChanged: (state) => state.applicant.recruiterChanged,
      recruiters: (state) => state.applicant.recruiters,
      filterQueries: (state) => state.application.filterQueries,
      sourcers: (state) => state.applicant.sourcers,
      sources: (state) => state.application.sources,
      skills: (state) => state.applicant.skills,
      countries: (state) => state.applicant.countries,
      interviewers: (state) => state.application.interviewers,
      users: (state) => state.applicant.users,
    }),
    applicationId() {
      return this.$route.params.id;
    },
    currentTab() {
      return this.$route.params.tab || 'emails';
    },
    codilityScore() {
      const { codilityTestLinks } = this;
      if (!codilityTestLinks?.length) return null;
      const filledTests = codilityTestLinks.filter(
        ({ result }) => result?.result || result?.result === 0
      );
      if (!filledTests.length) return null;
      const sumPercentage = filledTests.reduce((accumulator, current) => {
        const { max_result, result } = current.result;
        const percentage = (result / max_result) * 100;
        return accumulator + percentage;
      }, 0);
      return Math.round(sumPercentage / filledTests.length);
    },
    isSourcedToNonSourcingStages() {
      const { applicant, newStage } = this;
      return (
        applicant.source === 'sourced' &&
        applicant.stage.is_sourcing &&
        !newStage.is_sourcing
      );
    },
    recruitersList() {
      return interviewUtils.generateGroupList({
        groupsData: this.recruiters?.groups,
        itemsData: this.recruiters?.recruiters,
      });
    },
  },
  provide() {
    return {
      codilityTests: computed(() => this.codilityTests),
    };
  },
  watch: {
    dataNeedsUpdate(newValue, oldValue) {
      if (newValue.timestamp !== oldValue.timestamp) {
        this.getApplicantInfo(newValue.refresh);
      }
    },
    interviewsNeedUpdate() {
      this.getApplicantInterviews();
    },
    recruiterChanged() {
      this.getApplicantInterviews();
    },
    applicantInfo: {
      handler(newValue) {
        if (newValue) {
          this.applicant = newValue;
        }
      },
      immediate: true,
      deep: true,
    },
    currentTab: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          if (this.currentTab === 'emails') {
            this.getEmailSubjects();
          } else if (this.currentTab === 'interviews') {
            this.getApplicantInterviews();
            this.getTests();
          } else if (this.currentTab === 'notes') {
            this.getApplicantNotes();
          } else if (this.currentTab === 'tests') {
            this.getTests();
          }
        }
      },
      immediate: true,
      deep: true,
    },
    '$route.params.id': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          if (this.currentTab === 'emails') {
            this.getApplicantEmails();
            this.getEmailSubjects();
          } else if (this.currentTab === 'interviews') {
            this.getApplicantInterviews();
          } else if (this.currentTab === 'notes') {
            this.getApplicantNotes();
          }
          this.getApplicantTests();
          this.adjacentApplications = null;
        }
      },
    },
    '$route.query': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          if (newValue.notificationId) {
            this.markAsRead([newValue.notificationId]);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.getApplicantInfo();
    await this.getApplicantTests();
  },
  unmounted() {
    this.$store.commit('appTraffic/setPagination', null);
  },
  created() {
    this.getMenuOptions();
    this.getSources();
    this.getSkills();
    this.getCountries();
  },
  methods: {
    closeConfirmationPopupDialog() {
      this.isConfirmationDialogOpen = false;
      this.newStage = {};
    },
    closeStageActionPopupDialog() {
      this.isStageActionDialogOpen = false;
      this.stageEmailActions = [];
      this.isStageActionLoading = false;
    },
    async handleSubmitApplicationForm({ skills, attachments, ...form }) {
      const { id } = this.$route.params;
      const { onUploadProgress } = this;
      await api.applications.putApplication(id, form);
      if (skills && skills.length) {
        await api.applications.updateSkills(id, { skills });
      }
      if (form.sourcer_id) {
        await api.applications.updateAssignees(id, {
          sourcer_id: form.sourcer_id,
        });
      }
      if (attachments) {
        const data = new FormData();
        Object.keys(attachments).forEach((key) => {
          if (attachments[key]) {
            if (
              Object.prototype.toString.call(attachments[key]).slice(8, -1) ===
              'File'
            ) {
              data.append(key, attachments[key]);
            }
          }
        });
        await api.applications.updateAttachments(data, {
          id,
          onUploadProgress,
        });
      }
      if (this.newStage.id) {
        this.openConfirmationPopupDialog();
      }
      this.showSuccessMessage(
        'Applicant information has been updated!',
        3000,
        true
      );
      this.$store.commit('applicant/setDataNeedsUpdate');
    },
    onChangeTab(newTab) {
      this.$router.replace({ name: 'Applicant', params: { tab: newTab.path } });
    },
    onUploadProgress({ loaded, total }) {
      this.uploadProgress = Math.ceil((loaded / total) * 100);
    },
    async getApplicantEmails() {
      const { id } = this.$route.params;
      this.emails = [];
      this.isEmailLoading = true;
      const { data: response } = await api.applications.emails.get({
        id,
        subject: this.selectedSubject?.name ?? this.emailSubjects?.[0]?.name,
      });
      this.isEmailLoading = false;
      this.emails = [...response.emails.data].reverse();
    },
    async getApplicantInfo(refresh = true) {
      const filterQueries =
        Object.keys(this.filterQueries).length === 0
          ? JSON.parse(localStorage.getItem('filterQueries'))
          : this.filterQueries;
      const { id } = this.$route.params;
      this.isLoading = refresh;
      try {
        if (id) {
          const response = await api.applications.getApplicant(id);
          this.isLoading = false;
          this.applicant = response.data.response;

          if (!this.adjacentApplications) {
            const { data: { data: adjacentData } = {} } =
              await api.applications.getAdjacent({
                id,
                ...filterQueries,
              });
            this.adjacentApplications = adjacentData;
          }
          this.setPageTitle(this.applicant.full_name);

          this.$store.commit('appTraffic/setCurrentRoute', [
            {
              name: 'Applications',
              path: '/applications',
              icon: 'file-search02',
            },
            {
              name: this.applicant.full_name,
              path: `/applications/${this.applicant.id}`,
            },
          ]);
          this.$store.commit(
            'appTraffic/setPagination',
            this.adjacentApplications
          );
        }
      } catch (error) {
        if (error.status === 403) {
          this.$router.push({ name: 'Applications' });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getTests() {
      if (this.codilityTests.length || this.codilityTestsAreLoading) {
        return;
      }
      this.codilityTestsAreLoading = true;
      const { data: { tests } = {} } =
        await api.applications.codility.getTests();
      const filterBlocks = ['[OLD]', '[LIVE-CODING-ONLY]', '[TEST-DAY-ONLY]'];
      this.codilityTests = tests.filter(
        ({ is_archived, name }) =>
          !filterBlocks.some((item) => name.includes(item)) && !is_archived
      );
      this.codilityTestsAreLoading = false;
    },
    async getApplicantNotes() {
      const { id } = this.$route.params;
      const response = await api.applications.notes.get({ id });
      this.notes = response.data.response;
    },
    async getApplicantTests() {
      this.isApplicantTestsLoading = true;
      const { id } = this.$route.params;
      const response = await api.applications.codility.getTestLinks(id);
      this.codilityTestLinks = response.data.codility_test_links;
      this.isApplicantTestsLoading = false;
    },
    async getEmailSubjects() {
      const { id } = this.$route.params;
      if (!id) return;
      this.isEmailLoading = true;
      const { data: subjects } = await api.applications.emails.getSubjects({
        id,
      });
      this.isEmailLoading = false;
      this.emailSubjects = subjects.data.map((item) => ({
        ...item,
        name: item.subject,
      }));
    },
    async getMenuOptions() {
      this.isMenuOptionsLoading = true;
      if (!this.recruiters) {
        const { data: recruiters } = await api.users.recruiters();
        this.$store.commit('applicant/setRecruiters', recruiters.data);
      }
      if (!this.sourcers.length) {
        const { data: sourcers } = await api.users.sourcers();
        this.$store.commit('applicant/setSourcers', sourcers.data);
      }
      if (!this.users.length) {
        const { data: users } = await api.users.getAll();
        this.$store.commit('applicant/setUsers', users.data);
      }
      if (!this.interviewers) {
        const { data: interviewers } = await api.users.techInterviewers();
        this.$store.commit('application/setInterviewers', interviewers.data);
      }
      const { data: stages } = await api.stages.getStages();
      this.$store.commit('applicant/setStages', stages.data);
      this.isMenuOptionsLoading = false;
      this.menuOptions = {
        recruiter: this.recruiters?.recruiters,
        sourcer: this.sourcers,
        user: this.users,
        stage: stages.data,
        interviewers: this.interviewers,
      };
    },
    async getSources() {
      if (!this.sources.length) {
        const { data: sources } = await api.data.sources();
        this.$store.commit('application/setSources', sources.data);
      }
    },
    async getSkills() {
      if (!this.skills.length) {
        const { data: skills } = await api.data.skills();
        this.$store.commit('applicant/setSkills', skills.data);
      }
    },
    async getCountries() {
      if (!this.countries.length) {
        const { data: countries } = await api.data.countries();
        this.$store.commit('applicant/setCountries', countries.countries);
      }
    },
    async handleChangeStage(stage) {
      this.newStage = this.menuOptions.stage.find((item) => {
        return item.id === stage.id;
      });

      this.assigneesRequiredForStageChange = {
        interviewer: Boolean(this.newStage?.interviewer_required),
        recruiter: Boolean(this.newStage?.recruiter_required),
      };
      await this.getTests();

      const cvFile = (this.applicant.attachments ?? []).find(
        ({ type }) => type === 'cv_file'
      );

      const requiredFields = [
        this.applicant.first_name,
        this.applicant.last_name,
        this.applicant.email,
        this.applicant.country,
        this.applicant.source,
        this.applicant.assignees?.sourcer?.id,
        this.applicant.social_links?.linkedin_link || cvFile,
        this.applicant.application_skills.length > 0,
        this.applicant.commercial_exp_months,
        this.applicant.english_level,
        this.applicant.competency,
        this.applicant.compensation_plan === 'monthly'
          ? this.applicant.rate?.monthly_rate &&
            this.applicant.rate?.gig_overtime_hourly_rate
          : this.applicant.rate?.hourly_rate,
        this.applicant.application_skills.every((skill) =>
          Boolean(skill.years && skill.proficiency_level)
        ),
      ];

      const valid = requiredFields.every(Boolean);
      if (
        Boolean(this.applicant?.stage?.interview_required) &&
        !this.applicant.interview_is_filled
      ) {
        this.isReminderDialogOpen = true;
      } else if (this.isSourcedToNonSourcingStages && !valid) {
        await this.$refs.formDialog.reveal();
      } else {
        this.openConfirmationPopupDialog();
      }
    },
    handleHoverBadge() {
      clearTimeout(this.timer);
      this.showFastTrackedHint = true;
      this.timer = setTimeout(() => {
        this.showFastTrackedHint = false;
      }, 3000);
    },
    handleReceiveMessage(email) {
      this.emails = [...this.emails, email].sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    },
    handleReceiveNote(note) {
      this.notes = [...this.notes, note];
    },
    handleDeleteNote(note_id) {
      this.notes = this.notes.filter(({ id }) => id !== note_id);
    },
    handleEditNote(note) {
      this.notes = this.notes.map((item) => {
        if (item.id === note.id) {
          return note;
        }
        return item;
      });
    },
    async handleSelectSubject(subject) {
      this.selectedSubject = subject ?? this.emailSubjects?.[0];
      this.$router.replace({
        name: 'ApplicantEmails',
        params: {
          subject: encodeURI(this.selectedSubject?.name ?? ''),
        },
      });
      await this.getApplicantEmails();
    },
    handleUpdateAssignees({ recruiterId }) {
      this.applicant = {
        ...this.applicant,
        assignees: {
          ...this.applicant.assignees,
          recruiter:
            this.recruitersList.find(
              (recruiter) =>
                recruiter.type !== 'group' && recruiter.id === recruiterId
            ) ?? this.applicant.assignees?.recruiter,
        },
      };
    },
    async handleDeleteMessage(draft_id) {
      await api.applications.emails.delete({
        id: this.applicationId,
        draft_id,
      });
      this.emails = this.emails.filter((item) => item.draft_id !== draft_id);
      this.showSuccessMessage('Message has been deleted');
    },
    async onConfirmStageChange({
      interviewerId,
      recruiterId,
      skipInterview,
      interviewerGroup,
      recruiterGroup,
    }) {
      this.isStageChanging = true;
      const interviewerMap = [
        { condition: !this.newStage.interview_required, value: undefined },
        { condition: this.newStage.interviewer_required, value: interviewerId },
        { condition: !this.newStage.interviewer_required, value: recruiterId },
      ];
      const groupMap = [
        { condition: !this.newStage.interview_required, value: undefined },
        {
          condition: this.newStage.interviewer_required,
          value: interviewerGroup,
        },
        {
          condition: !this.newStage.interviewer_required,
          value: recruiterGroup,
        },
      ];
      const { data } = await api.applications.changeStage(this.applicationId, {
        stage_id: this.newStage.id,
        interviewer_id: interviewerMap.find(({ condition }) => condition)
          ?.value,
        interview_group_id: groupMap.find(({ condition }) => condition)?.value,
        skip_interview: skipInterview,
      });

      this.stageEmailActions = data.actions?.filter(
        ({ action }) => action === 'email'
      );
      if (this.stageEmailActions.length) {
        this.openStageActionPopupDialog();
      }
      this.applicant = {
        ...this.applicant,
        assignees: {
          ...this.applicant.assignees,
          recruiter: {
            ...(recruiterId
              ? this.menuOptions.recruiter.find(
                  ({ id, type }) => type !== 'group' && id === recruiterId
                )
              : this.applicant.assignees.recruiter),
          },
        },
        interviewers: {
          ...this.applicant.interviewers,
          ...(interviewerId
            ? {
                [this.newStage.id]: {
                  ...(this.menuOptions.interviewers?.interviewers ?? []).find(
                    ({ id }) => id === interviewerId
                  ),
                },
              }
            : {}),
        },
      };
      this.isStageChanging = false;
      this.updateStage(this.newStage);
      this.$store.commit('applicant/setDataNeedsUpdate');
      this.closeConfirmationPopupDialog();
      this.showSuccessMessage('Status was updated successfully');

      if (this.currentTab === 'interviews') {
        await this.getApplicantInterviews();
      }
    },
    onStageActionCancel() {
      this.closeStageActionPopupDialog();
      this.stageChangeEventContext = {};
    },
    onReminderCancel() {
      this.isReminderDialogOpen = false;
      this.isConfirmationDialogOpen = true;
    },
    onReminderClose() {
      this.isReminderDialogOpen = false;
    },
    onReminderConfirm() {
      this.isReminderDialogOpen = false;
      this.onChangeTab({ name: 'Interviews', path: 'interviews' });
    },
    async onStageActionPerform(file, selectedAction, sendAt) {
      const { id, link } = selectedAction;
      this.isStageActionLoading = true;
      let formData = new FormData();
      formData.append('data[0][action_id]', id);
      if (file) {
        formData.append('data[0][file]', file);
      }
      if (link) {
        formData.append('data[0][link]', link);
      }
      if (sendAt) {
        formData.append('data[0][send_at]', sendAt);
      }
      await api.applications.performTriggerActions(
        this.applicationId,
        formData
      );
      this.closeStageActionPopupDialog();
      this.showSuccessMessage('Email was sent successfully');
    },
    openConfirmationPopupDialog() {
      this.isConfirmationDialogOpen = true;
    },
    openStageActionPopupDialog() {
      this.isStageActionDialogOpen = true;
    },
    openApplicationFormDialog() {
      this.$refs.formDialog.reveal();
    },
    rejectApplicant(parameters) {
      const { id } = this.$route.params;
      return new Promise((resolve, reject) => {
        try {
          const response = api.applications.reject(id, parameters);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }).then((response) => {
        this.showSuccessMessage(
          `Applicant ${this.applicant.full_name} was rejected`
        );
        this.applicant = response.data.response;
        if (parameters.email_template_id) {
          this.handleSelectSubject({ name: parameters.subject });
        }
        return response;
      });
    },
    async unRejectApplicant() {
      const { id } = this.$route.params;
      const response = await api.applications.unReject(id);
      this.applicant = response.data.response;
      this.showSuccessMessage('Candidate was unrejected');
    },
    updateStage(stage) {
      this.applicant.stage = { ...stage };
    },
    async markAsRead(notificationIds) {
      try {
        await api.notifications.markAsRead({
          notifications: notificationIds,
        });
      } catch (error) {
        console.log(error);
      } finally {
        await this.$store.dispatch('notifications/fetchNotifications');
      }
    },
  },
};
</script>

<style scoped>
.box-wrapper {
  @apply flex;
}

.applicant-information {
  @apply mb-8
  pt-6
  flex
  flex-col
  overflow-auto;

  scroll-snap-align: center;
  scroll-behavior: smooth;
}

.applicant-activity {
  @apply pt-6
  sm:pb-6;

  scroll-snap-align: center;
  scroll-behavior: smooth;
}

.content {
  @apply flex flex-col w-full inset-0 duration-[400ms];
}

.activities-container {
  @apply mt-4
  border
  border-proxify-gray-100
  mx-8
  flex
  flex-col
  sticky
  top-[202px]
  bg-proxify-gray-50
  whitespace-pre-wrap
  overflow-hidden
  rounded-xl;

  height: v-bind(containerHeight);
}
</style>
