<script setup>
import PSeparator from '@/components/ProxifyUI/PSeparator.vue';
import {
  BaseApplicationSelector,
  BaseButtonDropdown,
  BaseInputField,
  BaseInputDropdown,
  BaseIcon,
  BaseLabel,
  BaseBadge,
} from '@/components/Base';
import { computed, ref, toRefs } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
const props = defineProps({
  todo: {
    type: Object,
    required: false,
    default() {
      return null;
    },
  },
  applicant: {
    type: Object,
    required: false,
    default() {
      return null;
    },
  },
});

const emit = defineEmits(['close-create-dialog']);
console.log(moment().format('YYYY-MM-DD'));

const { todo, applicant } = toRefs(props);
const store = useStore();
const users = computed(() => store.state.applicant.users);
const currentUser = computed(() => store.state.auth.user);
const getApplicationInitialData = (application) => {
  if (!application?.id) return null;
  return {
    ...application,
    stage_name: application.stage?.name,
  };
};

const todoObject = ref({
  application: todo.value?.application
    ? getApplicationInitialData(todo.value?.application)
    : getApplicationInitialData(applicant.value),
  assigned_to: todo.value?.assigned_to ?? null,
  body: todo.value?.body ?? null,
  due_date: todo.value?.due_date,
  is_completed: todo.value?.is_completed ?? false,
});
const isApplicationSelectorActive = ref(Boolean(todoObject.value.application));
const isDateSelectorActive = ref(Boolean(todoObject.value.due_date));
const isAssigneeSelectorActive = ref(Boolean(todoObject.value.assigned_to));

const handleCloseApplicationSelector = () => {
  isApplicationSelectorActive.value = false;
  todoObject.value.application = null;
};
const handleCloseDateSelector = () => {
  isDateSelectorActive.value = false;
  todoObject.value.due_date = null;
};
const handleCloseAssigneeSelector = () => {
  isAssigneeSelectorActive.value = false;
  todoObject.value.assigned_to = null;
};

const isTodayOrTomorrow = computed(() => {
  const dueDate = moment(todoObject.value.due_date);
  return (
    moment().isSame(dueDate, 'day') ||
    moment().add(1, 'days').isSame(dueDate, 'day')
  );
});

const assignLabel = computed(() => {
  if (!todoObject.value?.assigned_to) {
    return 'Assign to someone else';
  } else if (todoObject.value?.assigned_to?.id === currentUser.value.id) {
    return 'Assigned to you';
  }
  return `Assigned to ${todoObject.value?.assigned_to?.name}`;
});

const labelOptions = [
  { label: 'Today', value: moment().startOf('day').format() },
  { label: 'Tomorrow', value: moment().add(1, 'days').startOf('day').format() },
];
/**
 * Formats the given due date to an ISO string with the current time.
 * If no due date is provided, it returns null.
 *
 * @param {string|null} dueDate - The due date to format.
 * @returns {string|null} - The formatted due date as an ISO string, or null if no due date is provided.
 */
const formatDueDate = (dueDate) => {
  const currentTime = moment();
  return dueDate
    ? moment(dueDate)
        .hour(currentTime.hour())
        .minute(currentTime.minute())
        .toISOString()
    : null;
};
/**
 * Constructs the payload for creating or updating a to-do item. It incorporates the current state
 * of the to do object and additional logic for processing the due date and identifying the assigned user.
 *
 * @returns {Object} - The constructed payload with processed fields for the to do item.
 */
const constructPayload = () => {
  const formattedDueDate = formatDueDate(todoObject.value.due_date);
  return {
    ...todoObject.value,
    application: undefined,
    application_id: todoObject.value.application?.id ?? null,
    assigned_to: todoObject.value.assigned_to?.id ?? null,
    due_date:
      !todo.value?.due_date ||
      todo.value?.due_date !== todoObject.value.due_date
        ? formattedDueDate
        : undefined,
  };
};

// Main save function
const save = async () => {
  const body = constructPayload();
  const actionType = todo.value?.id ? 'update' : 'create';

  if (actionType === 'update') {
    await store.dispatch('todos/updateTodo', { body, todo_id: todo.value?.id });
  } else {
    await store.dispatch('todos/createTodo', body);
  }

  store.commit('ui/addSnackbarMessage', {
    title: `To-do successfully ${
      actionType === 'update' ? 'updated' : 'created'
    }`,
    type: 'success',
    displayDuration: 5000,
  });
  await store.dispatch('todos/fetchExpeditedTodos', currentUser.value.id);
  emit('close-create-dialog');
};

const { handleSubmit } = useForm();

defineExpose({
  save: handleSubmit(save),
});
</script>

<template>
  <div class="flex flex-col gap-4 px-6">
    <div>
      <div class="text-proxify-gray-700 pb-1.5">What do you want to do?</div>
      <BaseInputField
        v-model="todoObject.body"
        name="Todo Body"
        placeholder="Describe here.."
        type="textarea"
        max-length="90"
        required
        input-class="h-[112px]"
      ></BaseInputField>
    </div>
    <div class="flex flex-col cursor-pointer gap-2">
      <div
        class="text-proxify-primary-700 flex justify-between gap-1"
        @click="isApplicationSelectorActive = true"
      >
        <BaseBadge
          icon="link01"
          icon-size="20px"
          color="transparent"
          class="!p-0 !h-5 !font-semibold"
          badge-class="!text-proxify-primary-700"
        >
          Link applicant
        </BaseBadge>
        <BaseIcon
          v-show="isApplicationSelectorActive"
          name="x-close"
          size="18px"
          class="cursor-pointer"
          @click.stop="handleCloseApplicationSelector"
        />
      </div>
      <div
        v-show="!isApplicationSelectorActive"
        class="text-proxify-gray-600 text-body-sm font-normal"
      >
        To-do will appear on the applicants page too
      </div>
      <div v-if="isApplicationSelectorActive">
        <BaseApplicationSelector
          v-model="todoObject.application"
          name="Todo Applicant"
          map-options
        />
      </div>
    </div>
    <PSeparator class="text-proxify-gray-200"></PSeparator>
    <div class="flex flex-col cursor-pointer gap-2">
      <div
        class="text-proxify-primary-700 flex justify-between gap-1"
        @click.stop="isDateSelectorActive = true"
      >
        <BaseBadge
          icon="calendar"
          icon-size="20px"
          color="transparent"
          class="!p-0 !h-5 !font-semibold"
          badge-class="!text-proxify-primary-700"
        >
          Add a due date
        </BaseBadge>
        <BaseIcon
          v-show="isDateSelectorActive"
          name="x-close"
          size="18px"
          class="cursor-pointer"
          @click.stop="handleCloseDateSelector"
        />
      </div>
      <div
        v-show="isDateSelectorActive"
        class="flex gap-2"
      >
        <BaseLabel
          v-for="(item, index) in labelOptions"
          :key="index"
          class="icon-secondary-gray py-2.5 !px-4 !text-body-sm w-fit"
          :selected="
            todoObject.due_date &&
            moment(todoObject.due_date).isSame(item.value, 'day')
          "
          size="xl"
          rounded
          clickable
          :data-testid="`date-label-${item.label}`"
          @click="
            () =>
              (todoObject.due_date =
                !todoObject.due_date ||
                !moment(todoObject.due_date).isSame(item.value, 'day')
                  ? item.value
                  : null)
          "
        >
          {{ item.label }}
        </BaseLabel>
        <BaseButtonDropdown
          type="date"
          map-options
          option-label="label"
          option-value="value"
          :model-value="todoObject.due_date"
          disable-past-dates
          @update:model-value="
            (value) => {
              todoObject.due_date = value
                ? moment(value).format('YYYY-MM-DD')
                : null;
            }
          "
        >
          <template #activator>
            <BaseLabel
              class="icon-secondary-gray !px-4 py-2.5 !text-body-sm"
              prepend-icon="calendar"
              icon-size="20px"
              size="xl"
              rounded
              clickable
              :selected="todoObject.due_date && !isTodayOrTomorrow"
            >
              {{
                todoObject.due_date && !isTodayOrTomorrow
                  ? moment(todoObject.due_date).format('MMM DD')
                  : 'Select date'
              }}
            </BaseLabel>
          </template>
        </BaseButtonDropdown>
      </div>
    </div>
    <PSeparator class="text-proxify-gray-200"></PSeparator>
    <div class="flex flex-col cursor-pointer gap-2">
      <div
        class="text-proxify-primary-700 flex justify-between gap-1"
        @click="isAssigneeSelectorActive = true"
      >
        <BaseBadge
          icon="user_plus02"
          icon-size="20px"
          color="transparent"
          class="!p-0 !h-5 !font-semibold"
          badge-class="!text-proxify-primary-700"
        >
          {{ assignLabel }}
        </BaseBadge>
        <BaseIcon
          v-show="isAssigneeSelectorActive"
          name="x-close"
          size="18px"
          class="cursor-pointer"
          @click.stop="handleCloseAssigneeSelector"
        />
      </div>
      <div
        v-show="!todoObject.assigned_to"
        class="text-proxify-gray-600 text-body-sm font-normal"
      >
        Otherwise it will be assigned to you
      </div>
      <BaseInputDropdown
        v-show="isAssigneeSelectorActive"
        v-model="todoObject.assigned_to"
        name="Todo Assignee"
        type="avatar-leading"
        :options="users.filter(({ active }) => active)"
        placeholder="Select team member"
        map-options
        class="mt-1"
      ></BaseInputDropdown>
    </div>
    <PSeparator class="text-proxify-gray-200"></PSeparator>
  </div>
</template>
